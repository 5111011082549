import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
	image: JSX.Element;
	height?: number;
	children?: ReactNode;
	alignItems?: string;
}

const HeroSection = styled.section<{ height?: number }>`
	display: grid;
	height: ${(props) => props?.height && `${props.height}px`};
`;

const HeroContentWrapper = styled.div<{ alignItems: string }>`
	position: relative;
	display: grid;
	grid-area: 1/1;
	align-items: ${(props) => props.alignItems};
`;

const Hero = ({ image, height, children, alignItems = 'center' }: Props) => {
	return (
		<HeroSection height={height}>
			{/* You can use a GatsbyImage component if the image is dynamic */}
			{image}
			<HeroContentWrapper alignItems={alignItems}>{children}</HeroContentWrapper>
		</HeroSection>
	);
};

export default Hero;
