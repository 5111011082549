import * as React from 'react';
import styled from 'styled-components';

import Hero from './hero';
import { SmallerText, Subtitle } from '../../styles/typography';
import { ButtonHover, Container } from '../../styles/layout';
import { ImageEducation } from '../../assets/images';
import { graphql, useStaticQuery } from 'gatsby';

const EducationSectionContainer = styled.div`
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
`;

const Button = styled(ButtonHover)`
	align-self: flex-start;
	font-size: 14px;
	line-height: 24px;
	font-weight: 500;
	background: rgb(46, 87, 160);
	color: rgb(255, 255, 255);
	border-radius: 8px;
	padding: 16px;

	.icon {
		margin-right: 12px;
	}
`;

const Education = () => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					fbSiteUrl
					email
				}
			}
		}
	`);

	const textColor = 'rgb(255, 255, 255)';

	return (
		<Hero image={<ImageEducation />} height={586} alignItems={'center'}>
			<Container>
				<Subtitle textColor={textColor}>Izobraževanje in dogodki</Subtitle>
				<EducationSectionContainer>
					<SmallerText textColor={textColor} marginBottom={45}>
						Za prebivalce Tehnološkega parka in zunanje obiskovalce gostimo{' '}
						<strong>strokovna izobraževanja</strong> s področja informacijske tehnologije,{' '}
						<strong>tematske delavnice</strong> in dogodke za <strong>izmenjavo znanj</strong> ter izkušenj.
					</SmallerText>
					<Button href={site.siteMetadata.fbSiteUrl} target="_blank">
						<span className="icon icon-fb"></span>Aktualni dogodki
					</Button>
				</EducationSectionContainer>
			</Container>
		</Hero>
	);
};

export default Education;
