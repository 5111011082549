import * as React from 'react';
import styled from 'styled-components';

import { Subtitle, TitleUnderlined } from '../../styles/typography';
import { Container } from '../../styles/layout';
import { ImagePatternLeft, ImagePatternRight } from '../../assets/images';
import { down } from 'styled-breakpoints';

const WorkspaceConditionsSection = styled.section`
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;
	background-color: rgba(57, 171, 71, 0.05);

	&::before {
		content: url(${ImagePatternLeft});
		position: absolute;
		left: 0;
		top: 63px;
		z-index: 1;

		${down('md')} {
			display: none;
		}
	}

	&::after {
		content: url(${ImagePatternRight});
		position: absolute;
		right: 0;
		bottom: 63px;
		z-index: 1;

		${down('md')} {
			display: none;
		}
	}

	h2 {
		margin-bottom: 70px;

		${down('lg')} {
			br {
				display: none;
			}
		}
	}
`;

const WorkspaceConditionsNarowContainer = styled.div`
	padding-right: 150px;
	padding-left: 150px;

	${down('md')} {
		padding-right: 0;
		padding-left: 0;
	}
`;

const WorkspaceConditionsSectionContainer = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-gap: 24px;
	z-index: 2;

	${down('lg')} {
		grid-template-columns: 100%;
	}
`;

const WorkspaceConditionsSectionBox = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	width: calc(100% - 12px);
	background: rgb(255, 255, 255);
	box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	padding: 8px;

	&:last-child {
		margin-bottom: 0;
	}

	.icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 64px;
		height: 64px;
		border-radius: 9.6px;
		margin-right: 24px;
		background-color: rgba(0, 148, 68, 0.1);
		color: rgb(45, 166, 71);
		font-size: 30px;

		&-computer {
			font-size: 25px;
		}

		&-couch,
		&-dumbell {
			font-size: 20px;
		}
	}

	strong {
		font-size: 19px;
		font-weight: 500;
		line-height: 25px;

		${down('sm')} {
			font-size: 19px;
			line-height: 23px;
		}
	}

	${down('lg')} {
		width: calc(100% - 16px);
	}
`;

const workaspaceConditionsBoxes = [
	{
		icon: 'building',
		description: '1000+ m² pisarniškega prostora',
	},
	{
		icon: 'chair',
		description: '5 konferenčnih sob',
	},
	{
		icon: 'computer',
		description: 'Co-working prostor Kreativnica',
	},
	{
		icon: 'couch',
		description: 'Prostor za druženje in dogodke',
	},
	{
		icon: 'book',
		description: 'Interna knjižnica',
	},
	{
		icon: 'yoga',
		description: 'Soba za meditacijo in sprostitev',
	},
	{
		icon: 'dumbell',
		description: 'Sodobni fitnes z osebnim trenerjem',
	},
	{
		icon: 'ping-pong',
		description: 'Namizni tenis in ročni nogomet',
	},
	{
		icon: 'lego',
		description: 'Otroški in Lego kotiček',
	},

	{
		icon: 'education',
		description: 'Mentorstvo in izobraževanja',
	},
];

const WorkspaceConditions = () => {
	return (
		<WorkspaceConditionsSection>
			<Container>
				<WorkspaceConditionsNarowContainer>
					<Subtitle>
						Spodbudno okolje za <br />
						<TitleUnderlined>razvoj talentov</TitleUnderlined>
					</Subtitle>
					<WorkspaceConditionsSectionContainer>
						{workaspaceConditionsBoxes.map((box, index) => {
							return (
								<WorkspaceConditionsSectionBox key={index}>
									<span className={`icon icon-${box.icon}`}></span>
									<strong>{box.description}</strong>
								</WorkspaceConditionsSectionBox>
							);
						})}
					</WorkspaceConditionsSectionContainer>
				</WorkspaceConditionsNarowContainer>
			</Container>
		</WorkspaceConditionsSection>
	);
};

export default WorkspaceConditions;
