import * as React from 'react';
import styled from 'styled-components';

import { SmallerText, Subtitle, TitleUnderlined } from '../../styles/typography';
import { ButtonWithGradient, Container, MobileHeroImageContainer } from '../../styles/layout';
import { ImageOffice1 } from '../../assets/images';

const VisionSection = styled.section`
	padding-top: 80px;
	text-align: center;

	img {
		width: 100%;
		height: auto;
		margin-top: 80px;
	}
`;

const BadgeText = styled.div`
	font-size: 13px;
	line-height: 24px;
	width: 49px;
	height: 24px;
	font-weight: 500;
	padding: 4px 16px;
	margin: 0 auto 24px;
	color: rgb(53, 170, 71);
	background-color: rgba(73, 177, 71, 0.1);
	border-radius: 4px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
`;

const Vision = () => {
	return (
		<VisionSection>
			<Container>
				<BadgeText>Vizija</BadgeText>
				<Subtitle>
					<TitleUnderlined>Razvoj</TitleUnderlined> tehnoloških rešitev
				</Subtitle>
				<SmallerText marginTop={24} marginBottom={48}>
					Tehnološki park Ptuj je skupnost podjetij in strokovnjakov, ki sodelujejo pri razvoju visoko
					konkurenčnih <strong>tehnoloških rešitev na globalni ravni</strong>.<br /> Z zgledom želimo
					spodbujati rast regionalnega podjetniškega okolja in soustvarjati stimulativno delovno okolje,
					<br />
					ki bo posameznikom nudilo možnost za kakovosten <strong>razvoj potencialov in novih znanj</strong>.
				</SmallerText>
				<ButtonWithGradient to="/o-tehnoloskem-parku">
					Preberi več<span className="icon icon-arrow"></span>
				</ButtonWithGradient>
			</Container>
			<MobileHeroImageContainer>
				<ImageOffice1 />
			</MobileHeroImageContainer>
		</VisionSection>
	);
};

export default Vision;
