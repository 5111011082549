import * as React from 'react';
import { HeadProps } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ActionableBanner from '../components/sections/actionable-banner';
import Education from '../components/sections/education';
import WorkspaceConditions from '../components/sections/workspace-conditions';
import WorkspaceCompanies from '../components/sections/workspace-companies';
import Vision from '../components/sections/vision';
import { ImageSocialHome } from '../assets/images/social';

interface Props {
	location: Location;
}

// markup
const IndexPage = ({ location }: Props) => {
	return (
		<Layout location={location}>
			<Vision />

			<WorkspaceCompanies
				title="Člani tehnološkega parka"
				text={
					<>
						Na več kot 1200 m² skupaj ustvarja skoraj{' '}
						<strong>100 strokovnjakov, inženirjev in doktorjev znanosti</strong>.
					</>
				}
			/>

			<WorkspaceConditions />

			<Education />

			<ActionableBanner isHomePage={true} />
		</Layout>
	);
};

export const Head = (props: HeadProps) => {
	return (
		<Seo
			title={'Tehnološki park Ptuj'}
			description={
				'Tehnološki park Ptuj je skupnost podjetij in strokovnjakov, ki želijo aktivno prispevati k razvoju visoko konkurenčnih tehnoloških rešitev na globalni ravni.'
			}
			image={ImageSocialHome}
			locationPathName={props.location.pathname}
		/>
	);
};

export default IndexPage;
